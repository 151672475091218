<template>
  <v-dialog v-model="mostrar" :max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="danger" dark dense flat class="text-h6">
        Eliminar módulo
      </v-toolbar>

      <v-container grid-list-md>
        <b>¿Está seguro de elimiar el módulo?</b>
        <br />
        {{ modulo.nombre }} <br /><br />
        <i>Esta acción no podrá ser revertida.</i>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            small
            :disabled="loading"
            @click="cerrarForm()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="red"
            dark
            small
            :loading="loading"
            @click="eliminarModulo()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { eliminarModuloService } from "./modulos.service";

export default {
  props: {
    mostrar: Boolean,
    tipoCurso: String,
    idCurso: String,
    idModulo: String,
    tipoModulo: String,
    modulo: Object,
  },
  data: () => ({
    loading: false,
  }),
  created() {},
  methods: {
    async eliminarModulo() {
      try {

        this.loading = true;
        const serverResponse = await eliminarModuloService(this.tipoCurso, this.idCurso, this.idModulo, this.tipoModulo);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit('moduloEliminado', serverResponse.modulo);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    cerrarForm() {
      this.$emit("cancelar");
    },
  },
};
</script>